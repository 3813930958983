<template>
  <div class="app">
    <img src="@/assets/img/bg.png" class="app-bg" />
     <vue-particles
            color="#9494FF"
      :particleOpacity="1"
      :particlesNumber="70"
      shapeType="circle"
      :particleSize="2"
      linesColor="#fff"
      :linesWidth="0"
      :lineLinked="false"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="5"
      :hoverEffect="false"
      hoverMode="grab"
      :clickEffect="false"
      clickMode="push"
      style="z-index:-1"
        >
      </vue-particles>
    <!-- <div class="app-tit">益民社区大数据平台</div> -->
    <!-- <img src="@/assets/img/tit.png" class="tit-img" /> -->
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
#particles-js {
  width: 100%;
  height: 100%;
  // height: calc(100% - 100px);
  position: fixed;
  top: 0;
  left: 0;
}
.el-progress__text{
  color: white;
}
.el-progress path:first-child {
    stroke: #340087;
}
.app-tit{
  color: white;
  font-size: 2vw;
  text-align: center;
  line-height: 8vh;
  letter-spacing: 4px;
}
.pointer {
  cursor: pointer;
}
body,
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.app-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  background: #010a34;
}
ul,
li {
  margin: 0;
  padding: 0;
}
.titFont {
  font-family: "Hei";
}

.flex {
  display: flex;
}
.center {
  align-items: center;
}
.between {
  justify-content: space-between;
}
.absolute {
  position: absolute;
}
.centerJ {
  justify-content: center;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
input:focus {
  outline: none;
}
// 背景
.bgColor {
  background: linear-gradient(
    to right,
    rgba(91, 128, 168, 0.04),
    rgba(35, 123, 222, 0.27),
    rgba(64, 133, 210, 0.11),
    rgba(82, 139, 203, 0.08)
  );
}
// 标题
.index-tit {
  position: relative;
  color: #91d7ff;
  font-size: 20px;
  padding: 25px 20px 10px;
  letter-spacing: 5px;
  img {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    height: 100%;
  }
  .tit2 {
    color: #33bfd5;
    font-size: 10px;
    letter-spacing: 0;
  }
}

// 日期选择
.el-date-editor {
  background: #04072a;
  input {
    background: none;
    color: white !important;
  }
}
.el-input__inner {
  border: solid 1px #44eeff;
}
.el-date-editor .el-range__icon,
.el-range-separator {
  color: #44eeff !important;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
  background: #00CCFF
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
  border-radius: 10px;
  background: #ededed63;
}
</style>
