import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    searchKey: '' //存储词条的变量
  },
  mutations: {
    changeSearchKey(state, value) {
      state.searchKey = value
    }
  },
  actions: {
    setSearchKey(context,value){   
      context.commit('changeSearchKey',value)
   }
  },
  modules: {}
})
