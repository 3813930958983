import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入字体样式
import './common/font/font.css';
Vue.config.productionTip = false
// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import VueParticles from 'vue-particles'  
Vue.use(VueParticles)
// 引入element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import VueBus from 'vue-bus'
Vue.use(VueBus)
import vueSeamlessScroll from 'vue-seamless-scroll'
Vue.use(vueSeamlessScroll)

import VueScroll from '@david-j/vue-j-scroll';
Vue.use(VueScroll);


let vueMain = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export default vueMain